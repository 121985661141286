/* Application-wide Styles */
 @import 'node_modules/bootstrap/dist/css/bootstrap.min.css';

/*@import '~bootstrap/dist/css/bootstrap.css';*/

h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
h2, h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  justify-content: center;
  align-items: center;
  text-align: center;
}
body {
  margin: 2em;
}
body, input[type="text"], button {
  color: #333;
  font-family: Cambria, Georgia, serif;
}
/* everywhere else */
* {
  font-family: Arial, Helvetica, sans-serif;
}
